/*
 * @Author: Jackson
 * @Email: zhangjiajun@everimaging.com
 * @Date: 2021-01-12 17:17:36
 * @Description:
 *
 */

import "./App.scss";
import "src/assets/font/index.scss";

import { FTCanvasStore, FTImageStore, FTUserStore } from "src/store";
import { FTLang, utils } from "src/utils";

import { Layout } from "src/components";
import React from "react";
import { actionEnter } from "src/analyze/actions";
import { message } from "antd";

// import link_to_app_image from "src/assets/img/dialog_open_app/link_to_app_image.jpg";
// import entry_popup_banner from "src/assets/img/dialog_open_app/entry_popup_banner.jpg";

// import mousetrap from 'mousetrap';

class Root extends React.Component<{}, {}> {
  state = {
    loadLanguage: false
  };
  componentDidMount() {
    this.loadLanguage();
    FTCanvasStore.init();
    FTUserStore.getShowCountryAd();
    this.loadLoginSdk();
    this.loadPaymentSdk();
    this.stopDragDefaultEvent();
    this.addKeyBoardEvent();
  }

  addKeyBoardEvent = () => {
    // let Mousetrap = new mousetrap(document.body);
    // Mousetrap.bind('left', () => {
    // FTImageStore.prev()
    // });
    // Mousetrap.bind('right', () => {
    // FTImageStore.next()
    // });
  };

  stopDragDefaultEvent = () => {
    document.body.addEventListener("drop", (e) => {
      e.stopPropagation();
      e.preventDefault();
    });
    document.body.addEventListener("dragover", (e) => {
      e.stopPropagation();
      e.preventDefault();
    });

    // 阻止双击放大
    var lastTouchEnd = 0;
    document.addEventListener("touchstart", function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    });
    document.addEventListener(
      "touchend",
      function (event) {
        var now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );
    // 阻止双指放大
    document.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });
  };

  loadLoginSdk = () => {
    utils.loadLoginScript().then(() => {
      FTUserStore.checkUserLogin();
      // 注册google一键登陆的回调
      window["login"] &&
        window["login"].loginSuccess((res: any) => {
          FTUserStore.checkUserLogin();
          FTUserStore.getUserActivity();
          FTUserStore.getShowCountryAd();
        });
    });
  };

  loadLanguage = async () => {
    await FTLang.updateLanguage();
    this.setState({
      loadLanguage: true
    });
  };
  loadPaymentSdk = () => {
    utils.loadPaymentSdk();
  };
  render() {
    return (
      <React.Fragment>
        <Layout />
      </React.Fragment>
    );
  }
}

function App() {
  //  方案二
  // utils.myBrowserOS().isMobile && utils.mobileCommonSdkLoaded(() => {
  //   setTimeout(() => {
  //     const entryPopup = window.mobileCommon.EntryPopupInstance();
  //     entryPopup.show({
  //       projectType: "goart"
  //     })
  //   }, 500);
  // });

  React.useEffect(() => {
    function offline() {
      message.error(FTLang._("notification_network_offline"));
    }
    actionEnter({
      category_name: FTImageStore.currentClassify,
      category_id: FTImageStore.initClassifyId,
      resource_name: "",
      resource_id: FTImageStore.initEffectId
    });
    window.addEventListener("offline", offline);

    return () => {
      window.removeEventListener("offline", offline);
    };
  }, []);
  return <Root />;
}

export default App;
