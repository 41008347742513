import type {
  APIClassifyItem,
  APIEffectItem,
  ExampleEffectItem,
  FTImageStoreType,
  OriginTask,
  UsedEffect
} from "./types";
import {
  CutOutAvatar,
  FTLang,
  cloneCanvas,
  createRecaptcha,
  getObjectTypeString,
  getUploadUrl,
  hanldelFTUserProfile,
  rangNumber,
  recaptchaReady,
  renderRecaptcha,
  sleep,
  uploadS3File,
  utils
} from "src/utils";
import { EnumsShowStatus, FAVOR, errorCode } from "src/constant";
import {
  FTCheckNums,
  FTDeleteCollection,
  FTGetAiArtImage,
  FTGetCollection,
  FTGetCollectionList,
  FTGetCreateRemainNums,
  FTGetLoop,
  getEffectList,
  getExampleEffect
} from "src/server/api";
import FTEventTarget, { FTEVENT_CONSTANTS } from "src/utils/FTEventTarget";
import { GOART_TASK_LIST_KEY, LocalStorageManager } from "src/utils/local";
import {
  acitonCollection,
  actionClickUp,
  actionGenerateResult,
  actionGoartMonitor,
  actionImportImageClick,
  actionImportImageClose,
  actionImportImageResult,
  actionImportImageShow,
  actionSearch,
  actionShowLimitPopAnlazy,
  actionStartGenerate,
  actionWebError
} from "src/analyze/actions";
import {
  canvasToImage,
  cloneCanvasWithMaxSide,
  cloneCanvasWithSize,
  downloadFile,
  imageLoader
} from "src/utils/canvas";
import { configure, observable } from "mobx";

import FTCanvasStore from "./FTCanvasStore";
import FTUserStore from "./FTUserStore";
import { IEffectItem } from "./types";
import ImageProcessor from "src/ImageProcessor";
import clsx from "clsx";
import { message } from "antd";
import { waitFor } from "@testing-library/react";

/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-29 20:05:55
 * @Description:
 */

/** 判断是否完成收藏promise*/
let collectionStatus = false;
// mobx 配置
configure({
  enforceActions: "never" // 允许直接修改状态
});

message.config({
  top: utils.checkIsMobile() ? 32 : 60
});
function toItem(
  effectItem: APIEffectItem,
  classifyItem?: APIClassifyItem
): IEffectItem {
  const isAi = effectItem.special_type === "ai";
  return {
    prodId: effectItem.id,
    favorite: effectItem?.favorite,
    classifyId: classifyItem ? classifyItem.categoryId : "",
    classifyKey: classifyItem ? classifyItem.categoryId : "",
    name: effectItem.name,
    enName: effectItem.enName,
    id: effectItem.id,
    type: 2,
    url: effectItem.url,
    isvip: effectItem.isvip,
    isgrey: effectItem.isgray,
    blend: isAi ? 1 : effectItem.blend,
    firstCategory: classifyItem ? classifyItem.categoryName : "",
    firstCategoryEn: classifyItem ? classifyItem.categoryEnName : "",
    secondCategory: effectItem.tagName,
    aigc: isAi,
    isNew: effectItem.subScript?.includes("new"),
    isHot: effectItem.subScript?.includes("hot"),
    cropFace: effectItem.cropFace,
    detectFaces: effectItem.detectFaces,
    _id: effectItem?._id,
    beforeUrl: effectItem.beforeUrl,
    tipsContent: effectItem.tipsContent
  };
}
const FTImageStore = observable.object<FTImageStoreType>({
  taskList: [],
  originFile: null,
  showRequestErrorModal: false,
  showGuid: false,
  showStatus: EnumsShowStatus.origin,
  loadingGlobalEffect: true,
  /**标记是否请求完成 */
  loadingGlobalEffectNew: true,
  timerChooseClass: null,
  initAutoRun: false,
  initImageUrl: "",
  initAutoOpen: false,
  initClassifyId: "",
  initEffectId: "",
  mobileShowCenterAd: true,
  goartOriginImage: null,
  setMobileShowCenterAd: (value: boolean) => {
    FTImageStore.mobileShowCenterAd = value;
  },
  initTaskList() {
    const key = FTUserStore.getUserSuffiexKey(GOART_TASK_LIST_KEY);
    const taskList = LocalStorageManager.getItem(key);
    this.taskList = taskList || [];
  },
  currentImage: null,
  usedEffect: {},
  acturalSize: {
    width: 0,
    height: 0
  },
  currentSize: {
    width: 0,
    height: 0
  },
  resultSize: {
    width: 0,
    height: 0
  },
  prevEffect: null,
  greyImageElement: null,
  /**所有风格展示 */
  effectList: [],
  /**收藏列表描述对象 */
  effectCollectionObj: {
    status: false,
    arr: []
  },
  /**收藏列表Promise */
  collectionPromise: null,
  currentEffect: null,
  defaultEffect: "",
  imageElement: null, // 当前图片元素
  effectElement: null, // 特效生成的图片元素
  effectClassifyMap: [],
  currentClassify: "",
  currentClassifyId: "",
  intensity: {
    max: 100,
    min: 0,
    default: 100
  },
  defaultEffectClassify: "",
  currentIntensity: 50,
  currentTask: {
    taskId: ""
  },
  loading: false,

  effectClassify: [
    "Sketch",
    "Cartoon",
    "Pixel",
    "Watercolor",
    "Artist",
    "Art Genre",
    "Universal"
  ],
  choicenessList: [],
  effectTags: [],
  selectEffectTags: [],
  searchText: "",
  searchList: [],
  searchLoading: false,
  imageHD: false,

  currentEffectType: "",
  uploadFaceUrl: null,
  uploadImageUrl: null,
  artUploadImageUrl: null,
  artDownloadImageUrl: null,
  effectDownloadImageUrl: null,
  originImgUrl: "",
  taskId: "",
  artHDUploadImageUrl: null,
  checkRoot: { load: false, token: null, need: false, id: null },
  examplesList: [],
  /**免费次数 */
  freeNums: 0,
  progress: {
    upload: 0,
    task: 0,
    download: 0
  },
  hasShowTimeoutPopObj: {
    visible: false,
    id: ""
  },
  /**是否出现免费次数用完弹窗 */
  showNoCreditsPop: {
    visible: false,
    effect: null,
    hasGenerate: false
  },
  /**当前aigc生成模式 */
  mode: "free",
  recordsHasLoginGenerate: false,
  get totalProgress() {
    return (
      this.progress.upload * 0.5 +
      this.progress.task * 0.4 +
      this.progress.download * 0.1
    );
  },

  get getCurrentPostTask() {
    const id = this.currentEffect?.id;
    if (!id) {
      return null;
    }
    const task = this.taskList.find((item) => item.effctId === id);
    if (!task) return null;
    return task;
  },
  // MARK: 判断是否展示发布按钮
  get showPost() {
    if (!FTUserStore.isLogin) {
      return false;
    }
    const id = this.currentEffect?.id;
    if (!id) {
      return false;
    }
    const task = this.taskList.find((item) => item.effctId === id);
    if (!task) {
      return false;
    }
    if (task.status !== 1) {
      return false;
    }
    if (task.isPost) {
      return false;
    }
    return true;
  },
  postSuccess(taskId: string) {
    const index = this.taskList.findIndex((item) => item.taskId === taskId);
    if (index === -1) {
      return;
    }
    this.taskList[index].isPost = true;
    LocalStorageManager.setItem(
      FTUserStore.getUserSuffiexKey(GOART_TASK_LIST_KEY),
      this.taskList,
      LocalStorageManager.ONEYEAR
    );
  },
  addToTask(task: OriginTask) {
    const exit = this.taskList.find((item) => item.taskId === task.taskId);
    const isLogin = !!FTUserStore.userInfo;
    if (!isLogin) {
      return;
    }
    if (!exit) {
      this.taskList.unshift({
        originUrl: task.img,
        taskId: task.taskId,
        status: task.status,
        resultUrl: task.resultImg || "",
        isPost: false,
        effctId: task.templateId
      });
    } else {
      const index = this.taskList.findIndex(
        (item) => item.taskId === task.taskId
      );
      this.taskList[index] = {
        originUrl: task.img,
        taskId: task.taskId,
        status: task.status,
        resultUrl: task.resultImg || "",
        isPost: exit.isPost,
        effctId: task.templateId
      };
    }
    this.taskList = this.taskList.slice(0, 100);
    LocalStorageManager.setItem(
      FTUserStore.getUserSuffiexKey(GOART_TASK_LIST_KEY),
      this.taskList,
      LocalStorageManager.ONEYEAR
    );
  },
  getExampleEffectList() {
    getExampleEffect().then((res) => {
      const effectList = this.effectList;
      this.examplesList = res.data.map((item) => {
        const effectItem = effectList.find((ele) => {
          const id = ele.prodId || ele.id;
          return id + "" === item.templateId + "";
        });
        return {
          ...item,
          name: effectItem?.name ?? "",
          langKey: effectItem?.langKey ?? ""
        };
      });
    });
  },
  changeCurrentIntensity(value: number) {
    this.currentIntensity = value;
    FTCanvasStore.draw();
  },
  handelError(key, needTips = true) {
    this.showStatus = EnumsShowStatus.origin;
    this.loading = false;
    this.currentEffect = this.prevEffect;
    if (needTips) {
      message.error(FTLang._(key || "goart_effect_use_error"));
    }
  },
  // MARK:  轮询
  LoopExecute(id: string, effect: any, nums: number = 0, position?: string) {
    //0排队 1成功 2失败 3超时 /4生成中
    const statusType = {
      connectionTimeout: 3,
      pending: 0,
      fail: 2,
      making: 4,
      success: 1
    };
    this.progress.task = rangNumber(10, 90, this.progress.task + 5);
    FTGetLoop(id)
      .then((res: any) => {
        this.addToTask(res.data);
        //超过五十次停止
        if (nums >= 90) {
          this.hasShowTimeoutPopObj = {
            visible: true,
            id: this.currentEffect
          };
          this.handelError();
          return;
        }
        if (res.code === "000") {
          if (
            res.data.status === statusType.pending ||
            res.data.status === statusType.making
          ) {
            setTimeout(() => {
              this.LoopExecute(res.data.taskId, effect, nums + 1);
            }, 4000);
          } else {
            this.progress.task = 100;
            if (res.data.status === statusType.success) {
              this.progress.download = 10;
              actionGenerateResult(position || "", true, "");
              this.effectDownloadImageUrl = res.data.resultImg;
              this.taskId = res.data.taskId;
              this.originImgUrl = res.data.img;
              downloadFile(res.data.resultImg, (progress) => {
                this.progress.download = progress * 0.85;
              })
                .then(() => {
                  return imageLoader(res.data.resultImg);
                })
                .then((val) => {
                  this.progress.download = 100;
                  this.currentTask = res.data;
                  this.setUsedEffect({
                    effect,
                    target: val,
                    hasDraw: true
                  });
                  if (res.data.whetherViolations) {
                    actionGenerateResult(
                      position || "",
                      false,
                      "goart_effect_image_violation_rule"
                    );
                    this.handelError("goart_effect_image_violation_rule");
                  }
                  this.showStatus = EnumsShowStatus.result;
                  this.loading = false;
                  FTCanvasStore.showVideoLinkPopover();
                })
                .catch((error) => {
                  this.loading = false;
                  this.showStatus = EnumsShowStatus.origin;
                  actionGenerateResult(position || "", false, error);
                  this.handelError();
                });
            } else {
              if (
                res.data?.failReason?.includes("No face detected in the image")
              ) {
                actionGenerateResult(
                  position || "",
                  false,
                  "goart_aigc_face_check_error_toast"
                );
                this.handelError("goart_aigc_face_check_error_toast");
              } else {
                actionGenerateResult(position || "", false, "");
                this.handelError();
              }
            }
          }
        }
      })
      .catch((error) => {
        actionGenerateResult(position || "", false, error);
        this.handelError();
      });
  },
  handel_reset_pre_style_step() {
    this.currentEffect = this.prevEffect;
    if (this.currentEffect && this.usedEffect[this.currentEffect.id]) {
      this.effectElement = this.usedEffect[this.currentEffect.id];
      this.resultSize = {
        width: this.effectElement.naturalWidth,
        height: this.effectElement.naturalHeight
      };
      this.currentIntensity = this.currentEffect.blend * 100;
      FTCanvasStore.draw();
      this.showStatus = EnumsShowStatus.result;
    } else {
      this.showStatus = EnumsShowStatus.origin;
    }
  },
  async getAigcEffect(
    effect: any,
    tokens = "",
    checkRobot = false,
    position?: string
  ) {
    if (!FTImageStore.currentImage) {
      FTImageStore.uploadImage("click_effect_upload");
      return;
    }

    const exe = () => {
      this.currentEffect = this.prevEffect;
      if (this.currentEffect && this.usedEffect[this.currentEffect.id]) {
        this.effectElement = this.usedEffect[this.currentEffect.id];
        this.resultSize = {
          width: this.effectElement.naturalWidth,
          height: this.effectElement.naturalHeight
        };
        this.currentIntensity = this.currentEffect.blend * 100;
        FTCanvasStore.draw();
        this.showStatus = EnumsShowStatus.result;
      } else {
        this.showStatus = EnumsShowStatus.origin;
      }
    };
    this.recordsHasLoginGenerate = !!FTUserStore.userInfo;

    this.loading = true;
    let token = tokens;
    this.currentEffect = effect;
    this.progress.download = 0;
    this.progress.upload = 0;
    this.progress.task = 0;
    actionStartGenerate(position || "");

    // 获取上传链接
    try {
      if (effect.detectFaces) {
        if (!this.uploadFaceUrl) {
          const instance = new CutOutAvatar();
          const face = await instance.startProcess(this.currentImage as File);
          if (face?.result) {
            const file = face.file;
            if (effect.cropFace) {
              try {
                const key = await this.uploadFile(file, (progress) => {
                  this.progress.upload = progress;
                });
                this.uploadFaceUrl = key;
              } catch (error) {
                this.showRequestErrorModal = true;
                this.loading = false;
                return;
              }
            } else {
              // 如果只需要人脸检测
              let result: File = await new Promise((resolve) => {
                const newImage = cloneCanvasWithMaxSide(
                  this.imageElement as HTMLCanvasElement,
                  1024
                );
                newImage.toBlob((blob: Blob | null) => {
                  let files = new File([blob as Blob], this.currentImage.name);
                  resolve(files);
                }, "image/jpeg");
              });
              try {
                const key = await this.uploadFile(result, (progress) => {
                  this.progress.upload = progress;
                });
                this.uploadImageUrl = key;
              } catch (error) {
                this.showRequestErrorModal = true;
                this.loading = false;
                return;
              }
            }
          } else {
            this.loading = false;
            this.showStatus = EnumsShowStatus.origin;
            message.error({
              content: FTLang._("goart_aigc_face_check_error_toast"),
              className: clsx(
                "messageZindex",
                FTCanvasStore.isMobile && "mobileMessageClass"
              )
            });
            actionGenerateResult(position || "", false, face?.message ?? "");
            return;
          }
        }
      } else if (!this.uploadImageUrl) {
        let result: File = await new Promise((resolve) => {
          const newImage = cloneCanvasWithMaxSide(
            this.imageElement as HTMLCanvasElement,
            1024
          );
          newImage.toBlob((blob: Blob | null) => {
            let files = new File([blob as Blob], this.currentImage.name);
            resolve(files);
          }, "image/jpeg");
        });
        try {
          const key = await this.uploadFile(result, (progress) => {
            this.progress.upload = progress;
          });
          this.uploadImageUrl = key;
        } catch (error) {
          this.showRequestErrorModal = true;
          this.loading = false;
          return;
        }
      }
      this.progress.upload = 100;
      if (!this.checkRoot.load) {
        await createRecaptcha();
        this.checkRoot.load = true;
      }
      if (!checkRobot) {
        token = await recaptchaReady("aigc_check");
      }
      this.progress.task = 0;
      this.setMobileShowCenterAd(true);
      // let method =
      //   FTUserStore.isVip &&
      //   localStorage.getItem("fotor_goart_record_no_credits_pop") === "true" &&
      //   !FTUserStore.isSvip
      //     ? "credits"
      //     : this.mode;
      let method = this.mode;
      let result = await FTGetAiArtImage({
        img: effect.cropFace
          ? (this.uploadFaceUrl as string)
          : (this.uploadImageUrl as string),
        templateId: effect.prodId,
        googleToken: token,
        googleAction: checkRobot ? "" : "aigc_check",
        method: method
      });
      //处理code
      this.progress.task = 5;
      if (result.code === "000") {
        this.prevEffect = this.currentEffect;

        FTCheckNums().then((res) => {
          if (res.code === "000") {
            this.freeNums = res.data.remain;
          }
        });

        this.taskId = "";
        this.originImgUrl = "";
        this.LoopExecute(result.data.taskId, effect, 0, position || "");
      }
      //限流
      else if (result.code === "429") {
        if (FTUserStore.userInfo) {
          actionShowLimitPopAnlazy(
            !FTUserStore.userInfo
              ? 0
              : FTUserStore.isSvip
                ? 3
                : FTUserStore.isVip
                  ? 2
                  : 1
          );
          this.showNoCreditsPop = {
            ...this.showNoCreditsPop,
            visible: true,
            effect: { ...this.currentEffect },
            code: "429",
            time: result.time,
            hasIN: result.hasIN
          };
        } else {
          message.error(FTLang._("goart_aigc_root_maxs"));
        }
        this.loading = false;
        exe();
      } else if (result.code === "515") {
        this.loading = false;
        const payExe = () => {
          FTUserStore.showPaymnet({
            vipType: "vip",
            payType: "credit_sub",
            position: "goart_no_credits",
            otherParam: {
              scenesId: "012"
            },
            errorCallBack: () => {
              exe();
            },
            callback: () => {
              FTImageStore.showNoCreditsPop.visible = false;
              FTImageStore.getGoartEffect(
                FTImageStore.currentEffect,
                "retry_button",
                FTImageStore.showNoCreditsPop.hasGenerate
              );
            }
          });
        };

        const handelLogin = () => {
          FTUserStore.showLogin({
            isSignIn: false,
            options: {
              position: "goart_no_login"
            },
            isShowScenses: true,
            otherParam: {
              scenesId: "015"
            },
            errorCallBack: () => {
              exe();
            },
            callback: () => {
              this.getGoartEffect(this.currentEffect, "retry_button");
            }
          });
        };
        //说明积分不够
        if (result.data?.nextAction?.type === "login") {
          FTUserStore.checkUserLogin(
            () => {
              handelLogin();
            },
            () => {
              handelLogin();
            }
          );
        } else if (result.data?.nextAction?.type === "credits") {
          if (!FTUserStore.isVip || (FTUserStore.isVip && method === "free")) {
            this.showNoCreditsPop = {
              ...this.showNoCreditsPop,
              visible: true,
              effect: { ...this.currentEffect },
              code: "000"
            };
            exe();
          } else {
            let result = await FTGetCreateRemainNums();
            if (result.code === "000" && result.data?.subScore > 0) {
              this.showNoCreditsPop = {
                ...this.showNoCreditsPop,
                visible: true,
                effect: { ...this.currentEffect },
                code: "1"
              };
              exe();
            } else {
              payExe();
            }
          }
        } else if (
          FTUserStore.isVip &&
          !FTUserStore.isSvip &&
          method === "credits"
        ) {
          payExe();
        } else if (result.data?.nextAction?.type === "upgrade") {
          this.showNoCreditsPop = {
            ...this.showNoCreditsPop,
            visible: true,
            effect: { ...this.currentEffect },
            code: "1"
          };
          exe();
        } else {
          message.error(FTLang._("goart_aigc_root_maxs"));
          exe();
        }
      } else if (result.code === "831") {
        actionGenerateResult(position || "", false, "831");
        this.checkRoot = { ...this.checkRoot, need: true };
        this.loading = false;
        this.showStatus = EnumsShowStatus.origin;
        if (this.checkRoot.id !== null) {
          (window as any).grecaptcha.enterprise.reset(this.checkRoot.id);
        } else {
          this.rootExeCute(position);
        }
      } else if (result.code === "504") {
        this.loading = false;
        this.showStatus = EnumsShowStatus.origin;
        message.error({
          content: FTLang._(FTUserStore.get504ErrorMsg()),
          className: clsx(
            "messageZindex",
            FTCanvasStore.isMobile && "mobileMessageClass"
          )
        });
        actionGenerateResult(position || "", false, "504");
        if (!FTUserStore.userInfo) {
          FTUserStore.showLogin({
            isSignIn: false,
            options: {
              position: "download_fullsize"
            },
            isShowScenses: true,
            otherParam: {
              scenesId: "014",
              width: FTImageStore.currentSize.width,
              height: FTImageStore.currentSize.height,
              imgSrc: FTCanvasStore.canvas!.toDataURL("image/jpeg")
            },
            callback: () => {
              this.getAigcEffect(effect, token, checkRobot, position);
            },
            errorCallBack: () => {
              this.currentEffect = this.prevEffect;
            }
          });
          return;
        }
        if (FTUserStore.isSvip) {
          this.currentEffect = this.prevEffect;
        } else {
          FTUserStore.showPaymnet({
            vipType: "vip",
            position: "download_hd",
            otherParam: {
              scenesId: "012"
            },
            callback: () => {
              this.getAigcEffect(effect, token, checkRobot, position);
            },
            errorCallBack: () => {
              this.currentEffect = this.prevEffect;
            }
          });
        }
      } else {
        actionGenerateResult(position || "", false, "");
        this.handelError(undefined);
      }
    } catch (error) {
      if (error === errorCode.robotFail) {
        actionWebError({
          project_type: "goart",
          result_type: "robot_verification",
          fail_reason: "robot_load_is_failed"
        });
      }
      const getMessage =
        error instanceof Error ? error.message : (error as string);
      actionGenerateResult(position || "", false, getMessage);
      this.loading = false;
      message.error(FTLang._("goart_effect_use_error"));
      exe();
      // this.handelError(undefined);
    }
    //重置
    // if (localStorage.getItem("fotor_goart_record_no_credits_pop") !== "true") {
    //   this.mode = "free";
    // }
  },
  rootExeCute(position?: string) {
    this.checkRoot.id = renderRecaptcha(
      "goart_content-root_fra",
      (token: string) => {
        this.checkRoot = { ...this.checkRoot, need: false };
        this.getAigcEffect(this.currentEffect, token, true, position);
      }
    );
  },
  async uploadFile(file, fn: Function) {
    fn?.(0);
    const uploadUrls = await getUploadUrl();
    fn?.(10);
    let { uploadUrl, key, backupUploadUrl } = uploadUrls;
    await uploadS3File(uploadUrl, file!, (progress) => {
      fn?.(90 * progress);
    }).catch(async () => {
      fn?.(10);
      await uploadS3File(backupUploadUrl, file!, (progress) => {
        fn?.(90 * progress);
      });
    });
    return key;
  },
  async getGoartEffect(effect: any, position?: string, hasGenerate = false) {
    this.currentEffect = effect;
    if (!FTImageStore.currentImage) {
      return FTImageStore.uploadImage("click_effect_upload");
    }
    this.showNoCreditsPop.hasGenerate = hasGenerate;

    this.progress.download = 0;
    this.progress.upload = 0;
    this.progress.task = 0;

    if (effect?.aigc) {
      if (this.usedEffect[effect.id] && !hasGenerate) {
        this.currentEffect = effect;
        this.prevEffect = this.currentEffect;
        this.effectElement = this.usedEffect[effect.id];
        this.resultSize = {
          width: this.effectElement.naturalWidth,
          height: this.effectElement.naturalHeight
        };
        this.currentIntensity = effect.blend * 100;
        FTCanvasStore.draw();
        this.loading = false;
        this.showStatus = EnumsShowStatus.result;
        return;
      }

      this.getAigcEffect(effect, "", false, position);
      return;
    }
    this.prevEffect = this.currentEffect;
    if (effect.name === "Original") {
      this.currentEffect = effect;
      this.effectElement = null;
      FTCanvasStore.draw();
    } else {
      this.loading = true;
      this.currentEffect = effect;
      if (this.usedEffect[effect.id]) {
        this.effectElement = this.usedEffect[effect.id];
        this.resultSize = {
          width: this.effectElement.naturalWidth,
          height: this.effectElement.naturalHeight
        };
        this.currentIntensity = effect.blend * 100;
        FTCanvasStore.draw();
        this.loading = false;
        this.showStatus = EnumsShowStatus.result;
      } else {
        actionStartGenerate(position || "");
        if (!this.artUploadImageUrl) {
          let result: File = await new Promise((resolve) => {
            const newImage = cloneCanvasWithMaxSide(
              this.imageElement as HTMLCanvasElement,
              720
            );
            newImage.toBlob((blob: Blob | null) => {
              let files = new File([blob as Blob], this.currentImage.name);
              resolve(files);
            }, "image/jpeg");
          });
          try {
            const key = await this.uploadFile(result, (progress) => {
              this.progress.upload = progress;
            });
            this.artUploadImageUrl = key;
          } catch (error) {
            this.showRequestErrorModal = true;
            this.loading = false;
            return;
          }
        }
        this.progress.upload = 100;
        this.progress.task = 5;
        this.progress.download = 0;
        const interval = setInterval(() => {
          this.progress.task = rangNumber(10, 90, this.progress.task + 5);
        }, 1000);

        this.setMobileShowCenterAd(true);
        ImageProcessor.processByUrl(this.artUploadImageUrl!, effect.id)
          .then(async (res) => {
            clearInterval(interval);
            this.progress.task = 100;
            this.progress.download = 100;
            const isVertical = this.acturalSize.height > this.acturalSize.width;

            const ratio = this.acturalSize.width / this.acturalSize.height;
            const newWidth = isVertical
              ? this.acturalSize.height * ratio
              : this.acturalSize.width;
            const newHeight = isVertical
              ? this.acturalSize.height
              : this.acturalSize.width / ratio;
            this.goartOriginImage = cloneCanvasWithSize(
              res,
              res.width,
              res.height
            );
            const newCanvas = cloneCanvasWithSize(res, newWidth, newHeight); // goart 特效会生成1：1的图片 这里需要根据原图比例生成 新的图片
            const image = canvasToImage(newCanvas);
            if (effect.isgrey) {
              this.greyImageElement = await ImageProcessor.PreProcessGray(
                this.imageElement!
              );
            }
            actionGenerateResult(position || "", true, "");
            // goart 特效会压缩，所以使用原图的比例;

            this.setUsedEffect({
              effect,
              target: image
            });
            await sleep(500);
            this.showStatus = EnumsShowStatus.result;
            this.loading = false;
          })
          .catch((err) => {
            clearInterval(interval);
            // this.showStatus = EnumsShowStatus.origin;
            actionGenerateResult(position || "", false, err);

            this.currentEffect = this.prevEffect;
            if (this.currentEffect && this.usedEffect[this.currentEffect.id]) {
              this.effectElement = this.usedEffect[this.currentEffect.id];
              this.resultSize = {
                width: this.effectElement.naturalWidth,
                height: this.effectElement.naturalHeight
              };
              this.currentIntensity = this.currentEffect.blend * 100;
              FTCanvasStore.draw();
              this.showStatus = EnumsShowStatus.result;
            } else {
              this.showStatus = EnumsShowStatus.origin;
            }
            this.loading = false;
            message.error(FTLang._("goart_effect_use_error"));
            // this.handelError();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  setCurrentImage(target: any) {
    this.currentImage = target;
    this.reset();
  },

  setEffectList(list: IEffectItem[]) {
    //风格列表
    this.effectList = list.map((item: IEffectItem) => {
      const langId = (item.id as string).length > 5 ? item.prodId : item.id; //多语言文档使用的是prodId作为主键
      item.url = `${item.url}?v=${process.env.REACT_APP_VERSION}`;
      const tag_effect_id = `tag_effect_${langId}`;
      const effect_lang_key = `effect_lang_${langId}`;
      item.langKey = effect_lang_key;
      item.tagKey = tag_effect_id;
      item.secondCategory = FTLang.getSpecialLang(tag_effect_id, "en_US");
      return item;
    });
    const effectIdMap: Record<string, any> = {};
    this.effectList.forEach((item: any) => {
      const langId = item.id.length > 5 ? item.prodId : item.id; //多语言文档使用的是prodId作为主键
      effectIdMap[langId] = item;
    });
    this.choicenessList = JSON.parse(
      JSON.stringify(this.effectList.filter((item: any) => item.choiceness))
    );

    this.effectClassifyMap.forEach((classifyItem) => {
      if (classifyItem.effectIds) {
        classifyItem.list = classifyItem.effectIds
          .map((id: string) => {
            return effectIdMap[id];
          })
          .filter((item: any) => item);
        classifyItem.isNew = classifyItem.list.some((item: any) => item.isNew);
        classifyItem.list.forEach((effectItem) => {
          effectItem.firstCategory = classifyItem.title;
          effectItem.classifyKey = classifyItem.key;
          effectItem.classifyId = classifyItem.id;
        });
      } else {
        classifyItem.list = [];
        classifyItem.isNew = false;
      }
      classifyItem.lang = FTLang._(classifyItem.key);
      classifyItem.collapse = false;
    });
    if (this.defaultEffect) {
      const item = this.effectList.find(
        (item: any) => item.id === this.defaultEffect
      );
      if (item) {
        this.defaultEffectClassify = item.firstCategory;
      }
    }
  },

  setCurrentImageElement(target: HTMLCanvasElement, currentSize, acturalSize) {
    this.imageElement = target;
    this.currentSize = currentSize;
    this.acturalSize = acturalSize;
    this.effectElement = null;
    if (!FTCanvasStore.compareCanvas) {
      FTCanvasStore.compareCanvas = cloneCanvas(target);
      (FTCanvasStore.compareCanvas as HTMLCanvasElement).classList.add(
        "compare-canvas"
      );
      FTCanvasStore.compareCanvas.style.display = "none";
    }
    FTCanvasStore.draw();
  },

  setUsedEffect(data: UsedEffect) {
    this.resultSize = {
      width: data.target.naturalWidth,
      height: data.target.naturalHeight
    };
    this.effectElement = data.target;
    this.currentIntensity = data.effect.blend * 100;
    this.usedEffect[data.effect.id] = data.target;
    window.dispatchEvent(new Event("resize"));
    FTCanvasStore.draw(data?.hasDraw);
  },
  checkUsedEffect(effect: string) {
    return !!this.usedEffect[effect];
  },
  reset() {
    this.usedEffect = {};
    this.effectElement = null;
  },
  getEffectIndex() {
    return this.effectList.indexOf(this.currentEffect);
  },
  hasPrev() {
    if (!this.currentEffect) return false;
    if (this.currentEffect.name === "Original") {
      return false;
    }
    let index = this.getEffectIndex();
    return index > 0;
  },
  hasNext() {
    if (!this.currentEffect) return false;
    if (this.currentEffect.name === "Original") return true;
    let index = this.getEffectIndex();
    return index < this.effectList.length - 1;
  },
  next() {
    if (this.hasNext() && !this.loading) {
      let index = this.getEffectIndex();
      let newEffect = this.effectList[++index];
      this.getGoartEffect(newEffect);
    }
  },
  prev() {
    if (this.hasPrev() && !this.loading) {
      let index = this.getEffectIndex();
      let newEffect = this.effectList[--index];
      this.getGoartEffect(newEffect);
    }
  },
  getEffectTags() {
    return [
      "goart_effect_tag_Monochrome",
      "goart_effect_tag_Colorful",
      "goart_effect_tag_Bright",
      "goart_effect_tag_Dark",
      "goart_effect_tag_Saturated",
      "goart_effect_tag_Faded",
      "goart_effect_tag_Blue",
      "goart_effect_tag_Red",
      "goart_effect_tag_Green",
      "goart_effect_tag_Yellow",
      // "goart_effect_tag_Gray",
      "goart_effect_tag_White",
      "goart_effect_tag_Pink",
      "goart_effect_tag_Orange",
      "goart_effect_tag_Lines",
      "goart_effect_tag_Dotted",
      "goart_effect_tag_Blocks",
      "goart_effect_tag_Textured",
      "goart_effect_tag_Retro"
      // "goart_effect_tag_Pop",
    ];
  },
  // MARK: 获取收藏列表
  getCollectEffectList(language, hasSeach) {
    if (this.collectionPromise) {
      return collectionStatus
        ? Promise.resolve(this.effectCollectionObj.arr)
        : this.collectionPromise;
    }
    this.effectCollectionObj = {
      arr: [],
      status: false
    };
    this.collectionPromise = new Promise<Array<IEffectItem>>((resolve) => {
      FTGetCollectionList(FTUserStore.language)
        .then((res) => {
          if (res.code === "000") {
            this.effectCollectionObj = {
              arr: res.data.map((items) => toItem(items)),
              status: true
            };
            collectionStatus = true;
            //停留在当前页面
            if (this.currentClassifyId === FAVOR && !hasSeach) {
              this.searchList = [...this.effectCollectionObj.arr];
            }
            resolve(this.effectCollectionObj.arr);
          } else {
            throw new Error("");
          }
        })
        .catch(() => {
          resolve([]);
        });
    });
    return this.collectionPromise;
  },
  clearAllCollectionSamping() {
    this.effectCollectionObj = {
      status: false,
      arr: []
    };
    this.collectionPromise = null;
    if (this.currentClassifyId === FAVOR) {
      this.searchList = [];
    }
    this.effectClassifyMap.forEach((items) => {
      items.list.forEach((sonItems) => {
        sonItems.favorite = false;
      });
    });
    this.effectList.forEach((items) => {
      items.favorite = false;
    });
    this.searchList.forEach((items) => {
      items.favorite = false;
    });
    this.effectClassifyMap = [...this.effectClassifyMap];
    this.effectList = [...this.effectList];
    this.searchList = [...this.searchList];
  },
  // MARK: 获取所有风格列表,数据拆分
  getEffectList(language = "en_US") {
    return getEffectList(language)
      .then((res) => {
        const tags: string[] = [];
        res.data.forEach((classifyItem) => {
          tags.push(
            ...classifyItem.effectList.flatMap((effectItem) =>
              effectItem.tagName.split(",")
            )
          );
        });
        this.effectClassify = res.data.map(
          (classifyItem) => classifyItem.categoryName
        );
        this.effectTags = this.getEffectTags().map((item) => FTLang._(item));
        //分类
        this.effectClassifyMap = res.data.map((classifyItem) => {
          const id = classifyItem.categoryId;
          return {
            title: classifyItem.categoryName,
            enName: classifyItem.categoryEnName,
            key: id,
            id: id,
            needShowNew: true,
            isNew: classifyItem.effectList.some((effect) =>
              effect.subScript?.includes("new")
            ),
            isAvatar:
              id === "a4ca1dc1-9269-46e4-95ec-f2910db83db1" || id === "4",
            effectIds: classifyItem.effectList.map(
              (effectItem) => effectItem.id
            ),
            list: classifyItem.effectList.map((effectItem) =>
              toItem(effectItem, classifyItem)
            ),
            lang: classifyItem.categoryName,
            collapse: false
          };
        });
        const needHideNewsIds = ["10"];
        this.effectClassifyMap.forEach((classItem) => {
          if (needHideNewsIds.includes(classItem.id)) {
            classItem.needShowNew = false;
          }
        });
        this.effectClassifyMap = [...this.effectClassifyMap];
        this.effectList = this.effectClassifyMap.flatMap(
          (classify) => classify.list
        );
        if (this.examplesList.length > 0) {
          this.examplesList.forEach((item) => {
            const effectItem = this.effectList.find((ele) => {
              const id = ele.prodId || ele.id;
              return id + "" === item.templateId + "";
            });
            item.name = effectItem?.name ?? "";
            item.langKey = effectItem?.langKey ?? "";
          });
        }
        const handleFireSrcoll = (top) => {
          if (!this.loadingGlobalEffect) {
            return;
          }
          /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
          setTimeout(() => {
            FTEventTarget.getInstance().fireEvent({
              type: FTEVENT_CONSTANTS.SCROLL_TO_VIEW,
              data: { ...this.currentEffect, top: top }
            });
          }, 600);
        };
        if (this.defaultEffect) {
          const item = this.effectList.find(
            (item: any) => item.id === this.defaultEffect
          );
          if (item) {
            this.defaultEffectClassify = item.firstCategory;
          }
        }
        if (this.initEffectId) {
          let effect = this.effectList.find((item) => {
            const id = item.prodId || item.id;
            return id + "" === this.initEffectId;
          });
          if (effect) {
            this.currentEffect = effect;
            // 如果没有传classifyId，则默认选中当前选中的分类
            if (!this.initClassifyId) {
              this.currentClassifyId = effect.classifyId;
            } else {
              /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
              handleFireSrcoll(200);
            }
          }
        }
        if (this.currentClassify) {
          const effectClassify = this.effectClassifyMap.find((item) => {
            let searchText = this.currentClassify.toLocaleLowerCase();
            if (searchText.indexOf("_") >= 0) {
              searchText = searchText.split("_").join(" ");
            }
            return item.title.toLocaleLowerCase() === searchText;
          });
          if (effectClassify) {
            this.currentClassify = "";
            this.chooseSelectClassify(effectClassify.id, true);
            /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
            handleFireSrcoll(0);
            this.currentEffectType = effectClassify.title;
            this.currentClassifyId = effectClassify.id;
          } else {
            this.currentClassify = "";
          }
        }
        if (this.currentClassifyId) {
          const effectClassify = this.effectClassifyMap.find((item) => {
            return item.id + "" === this.currentClassifyId;
          });
          if (effectClassify) {
            this.currentClassify = "";
            this.chooseSelectClassify(effectClassify.id, true);
            /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
            handleFireSrcoll(0);
            this.currentEffectType = effectClassify.title;
            this.currentClassifyId = effectClassify.id;
          } else {
            this.currentClassify = "";
          }
        }
        if (this.initClassifyId) {
          const effectClassify = this.effectClassifyMap.find(
            (item) => item.id + "" === this.initClassifyId
          );
          if (effectClassify) {
            this.currentClassify = "";
            this.chooseSelectClassify(effectClassify.id, true);
            this.currentEffectType = effectClassify.title;
            this.currentClassifyId = effectClassify.id;
          }
        }
        if (this.initImageUrl && this.initEffectId) {
          this.startAUrl(this.initImageUrl, true);
        } else if (this.initClassifyId && this.initImageUrl) {
          this.showGuid = true;
          this.startAUrl(this.initImageUrl, false);
        } else if (this.initClassifyId) {
          this.showGuid = true;
        } else if (this.initImageUrl) {
          this.startAUrl(this.initImageUrl, false);
        }

        if (this.searchText.length > 0) {
          this.search();
        }

        this.initEffectId = "";
        this.initAutoRun = false;
        this.initImageUrl = "";
        this.initClassifyId = "";
      })
      .catch((error) => {
        actionGoartMonitor({
          position: "request_fail",
          fail_reason: error?.message
        });
      })
      .finally(() => {
        this.loadingGlobalEffect = false;
        this.loadingGlobalEffectNew = false;
      });
  },
  async uploadImage(
    position: string,
    file?: File | null,
    checkRobot = false,
    useFocuse = false,
    autorun = true
  ) {
    let img: Array<File>;
    actionImportImageClick(position);
    if (file) {
      img = [file as File];
    } else {
      actionImportImageShow(position);
      img = await utils.selectImageFile({
        multiple: false,
        useFocus: useFocuse
      });
      actionImportImageClose(position);
    }
    actionImportImageClose(position);
    if (img && img.length > 0) {
      actionImportImageResult(position, true);
      hanldelFTUserProfile();
      FTCanvasStore.changeLoading(true);
      this.uploadImageUrl = null;
      this.artUploadImageUrl = null;
      this.artHDUploadImageUrl = null;
      const checkResult = await utils.checkImage(img[0]);
      if (!checkResult.result) {
        message.error(FTLang._(checkResult.message));
        FTCanvasStore.changeLoading(false);
        actionGenerateResult(position, false, checkResult.message);
        return;
      }
      const [first] = img;
      this.originFile = first;
      let {
        canvas: imageElement,
        acturalSize,
        currentSize
      } = await utils.getImageFromFile(img[0]);
      this.setCurrentImageElement(imageElement, currentSize, acturalSize);
      this.setCurrentImage(img[0]);
      window.dispatchEvent(new Event("resize"));
      FTCanvasStore.changeLoading(false);
      if (!autorun) {
        return;
      }
      if (this.currentEffect && this.currentEffect !== "original") {
        actionClickUp(
          utils.toFixed(this.currentEffect.id, 0),
          this.currentEffect.enName,
          {
            button_position: "goart_effect"
          }
        );
        this.getGoartEffect(this.currentEffect);
      } else {
        //有默认风格
        let effect: IEffectItem | null = null;
        const defaultEffectTypeString = getObjectTypeString(this.defaultEffect);
        if (defaultEffectTypeString === "Object") {
          effect = this.defaultEffect;
        } else if (
          ["Number", "String"].includes(defaultEffectTypeString) &&
          this.defaultEffect
        ) {
          this.effectClassifyMap = this.effectClassifyMap.map((item: any) => {
            if (
              item.list.some((ite: any) => {
                if (ite.id === this.defaultEffect) {
                  effect = ite;
                  return true;
                }
                return false;
              })
            ) {
              item.collapse = true;
            }
            return item;
          });
        }
        if (effect) {
          actionClickUp(
            utils.toFixed(parseInt(effect.id as string), 0),
            effect.enName,
            {
              button_position: "goart_effect"
            }
          );
          this.getGoartEffect(effect);
        }
      }
    } else {
      if (this.currentEffect) {
        this.currentEffect = null;
      }
      actionImportImageResult(position, false);
    }
  },
  FTGetFreeNums() {
    FTCheckNums().then((res) => {
      if (res.code === "000") {
        this.freeNums = res.data.remain;
      }
    });
  },
  chooseSelectClassify(classifyId: string, force = false) {
    if (this.currentClassifyId === classifyId && !force) {
      return;
    }

    this.currentClassifyId = classifyId;
    const item = this.effectClassifyMap.find((item) => item.id === classifyId);
    // if (list) {
    //   list.collapse = !list.collapse;
    // }
    this.searchLoading = true;
    this.searchList = [];
    this.selectEffectTags = [];
    /* 点击分类搜索的不输入 */
    // this.searchText = classify.trim();
    this.searchText = "";
    if (item) {
      this.currentClassify = item.enName;
      if (this.timerChooseClass) {
        clearTimeout(this.timerChooseClass);
        this.timerChooseClass = null;
      }
      if (force) {
        this.searchList = [...item.list];
        this.searchLoading = false;
        this.timerChooseClass = null;
      } else {
        this.timerChooseClass = setTimeout(() => {
          this.searchList = [...item.list];
          this.searchLoading = false;
          this.timerChooseClass = null;
        }, 500);
      }

      // sleep(500,(timer)=>{
      //   this.timerChooseClass = timer;
      // }).then(() => {

      //   this.searchList = [...item.list];
      //   this.searchLoading = false;
      //   this.timerChooseClass = null;
      // });
    } else {
      this.currentClassify = "";
      this.currentClassifyId = "";
    }
  },

  addSelectTags(tag) {
    this.currentClassify = "";
    this.currentClassifyId = "";
    let index = this.selectEffectTags.indexOf(tag);
    if (index === -1) {
      // this.selectEffectTags = [...this.selectEffectTags, tag];
      this.selectEffectTags = [tag];
    } else {
      this.selectEffectTags.splice(index, 1);
      this.selectEffectTags = [...this.selectEffectTags];
    }
    this.searchText = this.selectEffectTags.map((item) => `${item}`).join(" ");
    if (this.selectEffectTags.length) {
      // 执行搜索操作
      this.search();
    } else {
      // 显示分类
      this.searchList = [];
    }
  },
  clearSelectTags() {
    this.selectEffectTags = [];
  },
  // MARK: 搜索列表
  async search() {
    this.searchLoading = true;
    if (FTImageStore.currentClassifyId === FAVOR) {
      // let arr: Array<IEffectItem> = [];
      // for (let j = 0; j < this.effectList.length; j++) {
      //   if (this.effectList[j].favorite) {
      //     arr.push(this.effectList[j]);
      //   }
      // }
      // arr = utils.unique(arr, "id");
      const arr = await this.getCollectEffectList(FTUserStore.language, true);
      this.searchList = [...arr];
      this.searchLoading = false;
      return;
    }
    // 支持使用 数字 空格和逗号（英文）进行搜索
    let searchText = [this.searchText.trim().toLocaleLowerCase()].filter(
      (item) => item
    );
    let searchList = new Set();
    let precisionList = new Set();

    for (let i = 0; i < searchText.length; i++) {
      let text = searchText[i];
      for (let j = 0; j < this.effectList.length; j++) {
        let effect = this.effectList[j];
        const localName = effect.name.toLocaleLowerCase();
        if (localName === text) {
          precisionList.add(effect);
        } else if (localName.startsWith(text)) {
          searchList.add(effect);
        }
        if (effect.firstCategory.toLocaleLowerCase().startsWith(text)) {
          searchList.add(effect);
        }
        if (
          effect.secondCategory
            ?.toLocaleLowerCase()
            .split(/[ |,]/g)
            .filter((item: any) => item)
            .some((item: any) => item.startsWith(text)) ??
          false
        ) {
          searchList.add(effect);
        }
      }
    }
    // }
    // 如果使用 array.from, 会使数据失去连通性
    for (let item of searchList.values()) {
      precisionList.add(item);
    }
    this.searchList = [];
    for (let item of precisionList.values()) {
      this.searchList.push(item);
    }

    // 删除重复的数据
    this.searchList = utils.unique(this.searchList, "id");
    this.searchLoading = false;

    if (this.searchText) {
      // 只埋搜索
      actionSearch(this.searchText);
    }
  },
  startSampleItem(item: ExampleEffectItem) {
    const effct = this.effectList.find(
      (ele) => ele.prodId === item.templateId || ele.id + "" === item.templateId
    );
    if (!effct) {
      return;
    }
    this.currentEffect = effct;
    return this.uploadImage("try_now_upload", null, false, true);
  },
  async startAUrl(url: string, autoRun = false) {
    const blob = await downloadFile(url);
    const lastName = new URL(url).pathname.split("/").pop() || "image.jpg";
    const file = new File([blob], lastName, {
      type: blob.type
    });
    this.uploadImage("from_url", file, false, false, autoRun);
  },
  async handel_collection(effectId, collection, callback) {
    const exe = async () => {
      let id = effectId[0];
      const exe = () => {
        // let finindex = this.effectList.findIndex((items) => items.id === id);
        // if (finindex > -1) {
        //   this.effectList[finindex].favorite = collection;
        // }
        let getItems: IEffectItem | null = null;

        try {
          //有多个一样的
          this.effectList.forEach((items) => {
            if (items.id === id) {
              items.favorite = collection;
              getItems = items;
            }
          });
          // let findIndex = this.effectList.findIndex((items) => items.id === id);
          // if (findIndex > -1) {
          //   this.effectList[findIndex].favorite = collection;
          // } else {
          //   return;
          // }
          this.effectClassifyMap.forEach((items) => {
            items.list.forEach((son) => {
              if (son.id === id) {
                son.favorite = collection;
              }
            });
          });
        } catch {}

        //搜藏接口请求完毕的
        if (this.effectCollectionObj.status) {
          if (getItems && collection) {
            //兼容异步存入
            let index = this.effectCollectionObj.arr.findIndex(
              (items) => items.id === getItems!.id
            );
            if (index === -1) {
              this.effectCollectionObj.arr.push(getItems);
            }
          }
          if (!collection) {
            let collectIndex = this.effectCollectionObj.arr.findIndex(
              (items) => items.id === id
            );
            if (collectIndex > -1) {
              this.effectCollectionObj.arr.splice(collectIndex, 1);
            }
          }
        }

        let findSearchIndex = this.searchList.findIndex(
          (items) => items.id === id
        );
        if (findSearchIndex > -1) {
          this.searchList[findSearchIndex].favorite = collection;
          // //搜索到收藏的
          if (this.currentClassifyId === FAVOR && !collection) {
            this.searchList.splice(findSearchIndex, 1);
          }
        }

        this.effectClassifyMap = [...this.effectClassifyMap];
        this.searchList = [...this.searchList];
      };
      if (collection) {
        await FTGetCollection(effectId)
          .then((res) => {
            if (res.code === "000") {
              message.success({
                content: FTLang._("goart_collection_success")
              });
              acitonCollection({
                is_success: true
              });
              callback && callback();
              exe();
            } else {
              throw new Error("");
            }
          })
          .catch(() => {});
        return;
      }
      await FTDeleteCollection(effectId)
        .then((res) => {
          if (res.code === "000") {
            message.success({
              content: FTLang._("goart_collection_fail")
            });
            acitonCollection({
              is_success: false
            });
            exe();
          } else {
            throw new Error("");
          }
        })
        .catch(() => {});
    };
    if (!FTUserStore.userInfo) {
      FTUserStore.showLogin({
        isSignIn: false,
        options: {
          position: "goart_ad_login"
        },
        isShowScenses: true,
        otherParam: {
          scenesId: "015"
        },
        callback: () => {
          exe();
        }
      });
      return;
    }
    try {
      await exe();
    } catch {}
  }

  //删除收藏
});
if (process.env.NODE_ENV !== "production") {
  window["FTImageStore"] = FTImageStore;
}

export default FTImageStore;
