import { DimondIcon, IconClose, RoyalIcon, ShareIcon } from "src/assets";
import styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { autorun } from "mobx";
import { FTCanvasStore, FTImageStore, FTUserStore } from "src/store";
import Bg from "src/assets/img/vipBg.jpg";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import { useIsMobile, useLanguage } from "src/hooks";
import dayjs from "dayjs";
import { EnumsShowStatus } from "src/constant";
import FtShare from "@ft/share";
import { FTAddUseNums, FTGetShareCode } from "src/server/api";
import JSon from "./data.json";
import { actionClickLimitPopAnlazy } from "src/analyze/actions";
let timer;
const LimitComp = ({ vipStatus, nums, setShowNums }) => {
  const { t } = useLanguage();

  useEffect(() => {
    setShowNums((state) => {
      return state + 1;
    });
    if (FTImageStore.showNoCreditsPop.time) {
      clearTimeout(timer);
      timer = setTimeout(
        () => {
          FTImageStore.showNoCreditsPop.visible = false;
          FTImageStore.getGoartEffect(
            FTImageStore.showNoCreditsPop.effect,
            "retry_button",
            FTImageStore.showNoCreditsPop.hasGenerate
          );
        },
        1000 * 60 * FTImageStore.showNoCreditsPop.time
      );
    }
  }, []);

  const [des, setDes] = useState(0);

  useEffect(() => {
    if (vipStatus !== "no") {
      return;
    }
    setTimeout(() => {
      setDes(des + 1 > 2 ? 0 : des + 1);
    }, 10000);
  }, [des, vipStatus]);

  return (
    <div className={styles["container"]}>
      <div
        onClick={(el) => {
          el.stopPropagation();
        }}
        className={styles["limit-inner"]}>
        <div className={styles["limit-loading"]}>
          <Player autoplay loop src={JSon} style={{ width: "120px" }}>
            <Controls visible={false} />
          </Player>
        </div>
        <p className={styles["title"]}>{t("goart_line_title_public")}</p>
        <p className={styles["des"]}>
          {vipStatus === "no" ? (
            <>
              {des === 0 && t("goart_line_title_public_1")}
              {des === 1 && t("goart_line_title_public_2")}
              {des === 2 && t("goart_line_title_public_3")}
            </>
          ) : (
            t("goart_line_title_public_vip")
          )}
        </p>
        {vipStatus === "no" && (
          <div
            onClick={() => {
              actionClickLimitPopAnlazy(
                "upgrade",
                !FTUserStore.userInfo
                  ? 0
                  : FTUserStore.isSvip
                    ? 3
                    : FTUserStore.isVip
                      ? 2
                      : 1
              );
              FTUserStore.showPaymnet({
                vipType: "vip",
                position: "goart_upgrade_vip",
                otherParam: {
                  scenesId: "012"
                },
                payType:
                  FTImageStore.showNoCreditsPop?.hasIN && !FTUserStore.isVip
                    ? "DailyPlan"
                    : "",
                callback: () => {
                  clearTimeout(timer);
                  FTImageStore.showNoCreditsPop.visible = false;
                  FTImageStore.getGoartEffect(
                    FTImageStore.showNoCreditsPop.effect,
                    "retry_button",
                    FTImageStore.showNoCreditsPop.hasGenerate
                  );
                }
              });
            }}
            style={{
              justifyContent: "center",
              width: "100%"
            }}
            className={clsx(styles["up-btn-limit"])}>
            <DimondIcon width={16} />
            {t(
              FTImageStore.showNoCreditsPop?.hasIN && !FTUserStore.isVip
                ? "goart_upgray_text_2"
                : "goart_upgray_text_1"
            )}
          </div>
        )}
        {vipStatus === "vip" && (
          <div
            onClick={() => {
              FTUserStore.showPaymnet({
                vipType: "vip",
                position: "goart_upgrade_vip",
                otherParam: {
                  scenesId: "012"
                },
                callback: () => {
                  clearTimeout(timer);
                  FTImageStore.showNoCreditsPop.visible = false;
                  FTImageStore.getGoartEffect(
                    FTImageStore.showNoCreditsPop.effect,
                    "retry_button",
                    FTImageStore.showNoCreditsPop.hasGenerate
                  );
                }
              });
            }}
            style={{
              width: "100%"
            }}
            className={clsx(
              styles["up-btn-limit"],
              styles["up-btn-pro-limit"]
            )}>
            <RoyalIcon width={16} />
            {t("goart_stack_tips_btn")}
          </div>
        )}

        {nums > 1 && (
          <div
            onClick={() => {
              actionClickLimitPopAnlazy(
                "close",
                !FTUserStore.userInfo
                  ? 0
                  : FTUserStore.isSvip
                    ? 3
                    : FTUserStore.isVip
                      ? 2
                      : 1
              );
              setShowNums(0);
              clearTimeout(timer);
              FTImageStore.showNoCreditsPop.visible = false;
            }}
            className={styles["cancel"]}>
            {t("goart_cancel_btn")}
          </div>
        )}
      </div>
    </div>
  );
};

const NoCreditsPop = () => {
  const [show, setShow] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [vipStatus, setVipStatus] = useState(
    FTUserStore.isVip ? (FTUserStore.isSvip ? "svip" : "vip") : "no"
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    autorun(() => {
      setVipStatus(
        FTUserStore.isVip ? (FTUserStore.isSvip ? "svip" : "vip") : "no"
      );
      setShowPop(FTImageStore.showNoCreditsPop.visible);
    });
  }, []);

  function handel_reset_pre() {
    FTImageStore.currentEffect = FTImageStore.prevEffect;
    if (
      FTImageStore.currentEffect &&
      FTImageStore.usedEffect[FTImageStore.currentEffect.id]
    ) {
      FTImageStore.effectElement =
        FTImageStore.usedEffect[FTImageStore.currentEffect.id];
      FTImageStore.resultSize = {
        width: FTImageStore.effectElement.naturalWidth,
        height: FTImageStore.effectElement.naturalHeight
      };
      FTImageStore.currentIntensity = FTImageStore.currentEffect.blend * 100;
      FTCanvasStore.draw();
      FTImageStore.showStatus = EnumsShowStatus.result;
    } else {
      FTImageStore.showStatus = EnumsShowStatus.origin;
    }
  }

  const [shareShown, setShareShown] = useState(false);

  const { t } = useLanguage();

  const [code, setCode] = useState("");
  const [curNums, setShowNums] = useState(0);

  useEffect(() => {
    autorun(() => {
      if (FTUserStore.userInfo) {
        if (FTImageStore.showNoCreditsPop?.invite) {
          FTGetShareCode(FTUserStore.userInfo.fotorToken).then((res) => {
            if (res.code === "000") {
              setCode(res.data?.inviterCode);
            }
          });
        }
      } else {
        //退出重置
        setCode("");
      }
    });
  }, []);

  useEffect(() => {
    //不是429触发的重置
    if (FTImageStore.showNoCreditsPop.code !== "429") {
      setShowNums(0);
    }
  }, [FTImageStore.showNoCreditsPop]);

  if (!showPop) {
    return null;
  }
  if (FTImageStore.showNoCreditsPop.code === "429") {
    return (
      <LimitComp
        setShowNums={setShowNums}
        nums={curNums}
        vipStatus={vipStatus}
      />
    );
  }
  return (
    <>
      <FtShare
        onClickMask={() => {
          setShareShown(false);
        }}
        subTitle={t("goart_invite_des1")}
        desc={t(
          !!FTImageStore.showNoCreditsPop?.invite
            ? "goart_invite_des"
            : "goart_share_des_title"
        )}
        inviteMode={FTImageStore.showNoCreditsPop?.invite}
        isMobile={isMobile}
        shareLink={
          FTImageStore.showNoCreditsPop?.invite
            ? `${process.env.REACT_APP_BASE_URL}referrer/${code}?redirect=${process.env.NODE_ENV === "production" ? "https://goart.fotor.com/" : "https://test-goart.fotor.com/"}`
            : `https://goart.fotor.com`
        }
        theme='light'
        onClose={() => {
          setShareShown(false);
        }}
        shareDesc={
          "Amazing AI editing and generating tool by Fotor @fotor_com. Sign up to get 10 free credits for AI image creating. "
        }
        title={t("goart_share_title")}
        env={"production"}
        open={shareShown}
        coverSrc={`${process.env.PUBLIC_URL}/img/background/Twitter.jpg`}
        shareTo={["discord", "facebook", "twitter", "pinterest", "linkedin"]}
        zIndex={30000}
        onShared={() => {}}
      />
      <div
        onClick={() => {
          if (isMobile) {
            handel_reset_pre();
            FTImageStore.showNoCreditsPop.visible = false;
            setShow(false);
          }
        }}
        className={styles["container"]}>
        <div
          onClick={(el) => {
            el.stopPropagation();
          }}
          className={styles["inner"]}>
          <div
            className={clsx(
              styles["inner-bg"],
              isMobile && styles["inner-bg-mobile"]
            )}>
            <img alt='' src={Bg} />
          </div>
          <div
            onClick={() => {
              handel_reset_pre();
              FTImageStore.showNoCreditsPop.visible = false;
              setShow(false);
            }}
            className={styles["close"]}>
            <IconClose width={12} />
          </div>
          <div className={styles["inner-content"]}>
            <p className={styles["title"]}>
              {FTImageStore.showNoCreditsPop?.invite
                ? t("goart_invite_title")
                : t("goart_limit_title")}
            </p>
            <p className={clsx(styles["public-des"])}>
              {FTImageStore.showNoCreditsPop?.invite
                ? t("goart_invite_des")
                : vipStatus === "vip"
                  ? t("goart_vip_limit_title", {
                      number: 1
                    })
                  : t("goart_free_limit_title")}
            </p>

            {!FTImageStore.showNoCreditsPop?.invite && (
              <div className={styles["tips"]}>
                {t("goart_limit_pop_tips", {
                  timer_key:
                    dayjs().add(1, "day").format("YYYY/MM/DD") + " 00:00:00"
                })}
              </div>
            )}

            {vipStatus !== "svip" && (
              <div
                onClick={() => {
                  FTUserStore.showPaymnet({
                    vipType: "vip",
                    position: "goart_upgrade_vip",
                    otherParam: {
                      scenesId: "012"
                    },
                    callback: () => {
                      localStorage.setItem(
                        "fotor_goart_record_no_credits_pop",
                        `${show}`
                      );
                      FTImageStore.showNoCreditsPop.visible = false;
                      setShow(false);
                      FTImageStore.getGoartEffect(
                        FTImageStore.showNoCreditsPop.effect,
                        "retry_button",
                        FTImageStore.showNoCreditsPop.hasGenerate
                      );
                    }
                  });
                }}
                className={clsx(
                  styles["up-btn"],
                  vipStatus === "vip" && styles["up-btn-vip"],
                  FTImageStore.showNoCreditsPop.code === "429" &&
                    styles["up-btn-vip-limit"]
                )}>
                {vipStatus === "vip" ? (
                  <RoyalIcon width={16} />
                ) : (
                  <DimondIcon width={16} />
                )}
                {t(
                  FTImageStore.showNoCreditsPop.code === "429"
                    ? "goart_stack_tips_btn"
                    : "goart_up_gray_limit_btn"
                )}
              </div>
            )}

            {vipStatus === "svip" && (
              <div
                onClick={() => {
                  FTUserStore.showPaymnet({
                    vipType: "vip",
                    position: "goart_upgrade_vip",
                    otherParam: {
                      scenesId: "012"
                    },
                    callback: () => {
                      localStorage.setItem(
                        "fotor_goart_record_no_credits_pop",
                        `${show}`
                      );
                      FTImageStore.showNoCreditsPop.visible = false;
                      setShow(false);
                      FTImageStore.getGoartEffect(
                        FTImageStore.showNoCreditsPop.effect,
                        "retry_button",
                        FTImageStore.showNoCreditsPop.hasGenerate
                      );
                    }
                  });
                }}
                className={clsx(styles["up-btn"], styles["up-btn-vip"])}>
                {<RoyalIcon width={16} />}
                {t("goart_stack_tips_btn")}
              </div>
            )}
            {/* 
            {vipStatus === "vip" && (
              <div
                className={clsx(
                  styles["checkbox"],
                  show && styles["checkbot-active"]
                )}>
                <div onClick={handel_records}>
                  <YesIcon width={12} />
                </div>
                {t("goart_checkbox_confirm")}
              </div>
            )} */}

            {vipStatus === "no" && (
              <>
                <div className={styles["upgrap-gap"]}>
                  <span></span>
                  {t("goart_or")}
                  <span></span>
                </div>

                <p className={styles["upgrap-des"]}>{t("goart_share_des")}</p>

                <div
                  onClick={() => {
                    setShareShown(true);
                    if (!FTImageStore.showNoCreditsPop?.invite) {
                      FTAddUseNums();
                    }
                  }}
                  className={styles["share-btn"]}>
                  <ShareIcon width={16} />
                  {t("goart_share_btn")}
                </div>
              </>
            )}

            {!FTImageStore.showNoCreditsPop?.invite && vipStatus === "no" && (
              <div className={styles["upgray-des"]}>
                {t("goart_share_community_nums")}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NoCreditsPop;
